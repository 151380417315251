<template>
  <!--维护通知-->
  <van-dialog   show-cancel-button>
    <div class="p-3 pre-wrap fs-14">
      <div class="">
        全年最高额度最简单的机器人新用户活动‼<br/>
        ✔还没报名的店主，抓紧时间报名<br/>
        ✔报名了的店主，赶紧邀请店长一起注册吧<br/>
        点击下方按钮了解详情
      </div>
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: "TheNoticeDialog"
}
</script>
<script setup>

// const props = defineProps({
//   modelValue: {
//     type: Boolean,
//     default: false
//   },
// })
// const emits = defineEmits('update:modelValue')
//
// const myVisible = computed({
//   set(val) {
//     emits('update:modelValue', val)
//   },
//   get() {
//     return props.modelValue
//   }
// })
</script>

<style scoped>

</style>
